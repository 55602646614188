import React from 'react';
import cn from "classnames";
import People from "../../images/block4_ico1.svg";
import Building from "../../images/building.png"
import LeftMan from "../../images/left_man.png"


const FormFooter = ({ template = 'default' }) => {
    return (
        <>
            {template === 'default' &&
                <div className={cn('img__wrapper')}>
                    <img src={LeftMan} alt="" className={'img-left-man'} height={230}/>
                    <img src={Building} alt="" className={'img-salon'}/>
                    <img src={People} alt="" className={'img-people'}/>
                </div>}
            {template === 'common' &&
                <div className={cn('img__wrapper')}>
                    <img src={LeftMan} alt="" className={'img-left-man'} height={230}/>
                    <img src={Building} alt="" className={'img-salon'}/>
                    <img src={People} alt="" className={'img-people'}/>
                </div>
            }
        </>
    )
}

export default FormFooter;