import React from "react";

import LeftCar from "../../images/templates/avtoset_rf/left_car.png";
import RightCar from "../../images/templates/avtoset_rf/right_car.png";

const Footer = ({template = 'default'}) => {
    return (
        <>
            {template === 'common' &&
                <div className={'footer'}>
                    <div className={'footer__wrapper'} />
                    <div className={'footer__fake-block'} />
                </div>
            }

            {template === 'default' &&
                <div className={'footer'}>
                    <div className={'footer__wrapper'} />
                    <div className={'footer__fake-block'} />
                </div>
            }
            {template === 'ao_medicina' && <></>}
            {template === 'avtoset_rf' &&
                <div className={'footer'}>
                    <div className={'footer__wrapper'} >
                        <img src={LeftCar} alt="" className={'img-left-car'}/>
                        <img src={RightCar} alt="" className={'img-right-car'}/>
                    </div>
                    <div className={'footer__fake-block'} />
                </div>
            }

        </>
    )
}
export default Footer;
